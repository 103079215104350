<template>
  <v-container fluid fill-width fill-height class="pa-0 bg-uni">
    <div class="gray-scale"></div>
    <v-container fill-height class="align-content-center">
      <v-card
        min-height="550"
        width="500"
        elevation="10"
        dark
        class="d-flex justify-space-between centered align-content-center flex-column glass-card"
      >
        <v-card-text class="px-11">
          <v-img src="@/assets/img/qr.jpg" class="rounded-lg my-4" />
          <h2 class="mb-4">{{ $_t("dashboard.wechat_id") }} : MMJJPP0077</h2>
          <h2>{{ $_t("dashboard.email") }} : mj.pourrazzaghi@sino-uk.org</h2>
        </v-card-text>
        <v-col cols="12" v-if="!user">
          <router-link
            class="white--text text-decoration-none"
            :to="{ name: 'auth.login' }"
            >{{ $_t("dashboard.router_title.login") }}</router-link
          >
        </v-col>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ContactUs",
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
  },
};
</script>

<style scoped>
.bg-uni {
  background: url("../../../../src/assets/img/uni2.jpg") center no-repeat !important;
  background-size: cover !important;
  position: relative;
}
.gray-scale {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.47);
}
.centered {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
